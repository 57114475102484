/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "getting-used-to-the-sound-of-your-own-voice-with-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-used-to-the-sound-of-your-own-voice-with-hearing-aids",
    "aria-label": "getting used to the sound of your own voice with hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting Used to the Sound of Your Own Voice with Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever heard yourself in a telephone message or home movie? You probably felt like you sounded much different from the recording then you do in real life. Your voice is part of your identity and you hear it a lot. But there are a number of scientific reasons why we hear it differently through a device and why you may experience this effect with new hearing aids too."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-ear-bones-connected-to-the",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-ear-bones-connected-to-the",
    "aria-label": "the ear bones connected to the permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Ear Bone’s Connected to the…"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The sound of your own voice reaches your brain differently when you say it versus when it comes from somewhere else. Probably the biggest reason you hear your own voice differently is due to bone-conduction. When you talk, your vocal cords create vibrations in our skull that reach the cochlea (the ear bone). This makes your own voice sound lower and richer than it actually is. When you hear with your ears you are using air-conduction. You can actually replicate this by putting your fingers in your ears and talking. Try it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hearing aids, there are two factors that cause this effect. First, you are hearing it louder with your ears (through air-conduction). Second, you are hearing higher pitches in your voice that you have been missing. Although this changes how you hear your own voice, within a week your brain will determine this is the new normal and you won’t notice it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By the way, this isn’t just a hearing thing. The brain has a similar response to smells – both good and bad. For example, when you meet someone wearing a fragrance, you only notice it for a short amount of time. But the scent is still there. This is because our brains are primarily focused on alerting us to new things in our environment. And once its no longer new, our brain tends to ignore it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "practice-makes-perfect-read-aloud",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#practice-makes-perfect-read-aloud",
    "aria-label": "practice makes perfect read aloud permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Practice Makes Perfect: Read Aloud"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the beginning, some people are a little self-conscious about the volume of their voice or perceive it as louder. But almost every hearing aid user will tell you that with a little practice, they not only get used to it, but are able to determine the appropriate speaking volume better than before."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So besides just wearing your hearing aids, you should practice hearing yourself speak. For instance, you can read a book or the newspaper in a quiet place in your home."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you practice reading out loud for a couple of minutes a day, you’ll be used to the sound of your voice and be comfortable with your speaking volume by the end of the first week."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’re not alone on your journey to better hearing. If you’re having a hard time adjusting to the sound of your own voice, don’t hesitate to contact us. We’re happy to help get to the bottom of the issue so that you can enjoy the benefits of your new hearing aids."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
